// const url = "https://nodepune-investmentapp.mobiloitte.io/api/v1"; //staging
const url = "https://nodepune-investment.mobiloitte.io/api/v1"
// const url = "http://172.16.2.14:3044/api/v1"; //local

// const url = "http://172.16.2.44:3044/api/v1";
// const url = "http://172.16.2.14:3044/api/v1";

export const socketNotification = "";
export const baseUrl = "";

const user = `${url}/user`;
const admin = `${url}/admin`;

const ApiConfig = {
  //Auth flow api
  userLogin: `${admin}/login`,
  forgotPassword: `${admin}/forgotPassword`,
  verifyOTP: `${admin}/verifyOTP`,
  resendOtp: `${admin}/resendOtp`,
  resetPassword: `${admin}/resetPassword`,
  changepassword: `${admin}/resetPassword`,
  loginOTPVerify: `${admin}/loginOTPVerify`,
  changePasswordSendOTP: `${admin}/changePasswordSendOTP`,
  changePasswordOTPVerify: `${admin}/changePasswordOTPVerify`,

  //Blogs api
  listBlog: `${url}/blog/listBlog`,
  deleteBlogData: `${url}/blog/deleteBlogData`,
  viewBlog: `${url}/blog/viewBlog`,
  addBlog: `${url}/blog/addBlog`,

  //Learn api
  listLearn: `${url}/learn/listLearn`,
  deleteLearnData: `${url}/learn/deleteLearnData`,
  viewLearn: `${url}/learn/viewLearn`,
  listBlog: `${url}/blog/listBlog`,
  deleteBlogData: `${url}/blog/deleteBlogData`,
  viewBlog: `${url}/blog/viewBlog`,
  addBlog: `${url}/blog/addBlog`,
  updateBlog: `${url}/blog/updateBlog`,

  //Learn api
  listLearn: `${url}/learn/listLearn`,
  deleteLearnData: `${url}/learn/deleteLearnData`,
  viewLearn: `${url}/learn/viewLearn`,
  addLearn: `${url}/learn/addLearn`,
  updateLearn: `${url}/learn/updateLearn`,

  //profile-api
  viewProfile: `${admin}/viewProfile`,

  //investment management
  investmentListbySearch: `${url}/admin/investmentListbySearch`,
  viewInvestment: `${url}/admin/viewInvestmentData`,

  viewProfile: `${admin}/viewProfile`,

  //user management
  userDashboard: `${admin}/userDashboard`,

  listUser: `${admin}/listUser`,
  userDetails: `${admin}/viewUserProfile`,
  blockUnblock: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  allBanner: `${url}/banner/get-all-banners`,
  addBanner: `${url}/banner/addBanner`,
  editBanner: `${url}/banner/editBanner`,
  deleteBanner: `${url}/banner/deleteBanner`,
  allBannerWeb: `${url}/banner/get-all-banners-of-web`,
  addBannerWeb: `${url}/banner/addbannerOfWeb`,
  editBannerWeb: `${url}/banner/editBannerofWeb`,
  deleteBannerWeb: `${url}/banner/deleteBannerofWeb`,
  editDiscount: `${admin}/updatePlanData`,
  announcementContentList: `${url}/announcement/announcementContentList`,
  viewannouncementContent: `${url}/announcement/viewannouncementContent`,
  editannouncementContent: `${url}/announcement/editannouncementContent`,
  deleteannouncement: `${url}/announcement/deleteannouncement`,
  addannouncementContent: `${url}/announcement/addannouncementContent`,
  discountManagementData: `${url}/buy/getTradingBotDetails`,
  dashboardCount: `${url}/admin/dashboard`,
  assetsData: `${admin}/assetsTransactionList`,
  referralData: `${admin}/referralList`,
  assetsTransactionList: `${url}/admin/assetsTransactionList`,
  approveWithdrawReq: `${url}/admin/approveWithdrawReq`,
  viewUserSubscriptionDetails: `${url}/admin/viewUserSubscriptionDetails`,
  viewUserLoginActivity: `${url}/admin/viewUserLoginActivity`,
  viewUserWalletTransactions: `${url}/admin/viewUserWalletTransactions`,
  viewUserConnectedExchanges: `${url}/admin/viewUserConnectedExchanges`,
  getFeedback: `${url}/feedback/getFeedbackList`,
  viewFeedback: `${url}/feedback/viewFeedback`,

  getListStatic: `${url}/static/staticContentList`,

  getSingleStaticContent: `${url}/static/viewStaticContent`,
  editStaticContent: `${url}/static/editStaticContent`,
  contactUsList: `${url}/admin/contact-us-list`,
  viewContactUs: `${url}/admin/viewContactUs`,
  newsLettersubscriberList: `${url}/admin/newsLettersubscriberList`,
  sendNotificationToAllUser: `${url}/admin/sendNotificationToAllUser`,
  adminDashbordcount: `${url}/admin/adminDashbordcount`,
  viewUserReferralDetails: `${url}/admin/viewUserReferralDetails`,

  //investors

  investorDetails: `${url}/property/investorDetails`,
  viewInvestmentData: `${url}/admin/viewInvestmentData`,

  // properties
  adminGetAllProperties: `${url}/admin/adminGetAllProperties`,
  adminDeleteProperty: `${url}/admin/adminDeleteProperty`,
  addShareProperty: `${url}/admin/addShareProperty`,
  addFinancialDetails: `${url}/property/addFinancialDetails`,
  add: `${url}/property/add`,
  propertyInvestor: `${url}/property/propertyInvestor`,
  eachPropertyInvestor: `${url}/property/eachPropertyInvestor`,
  editProperty: `${url}/property/editProperty`,
  particularPropertyDetails: `${url}/property/particularPropertyDetails`,

  // faq
  faqList: `${url}/faq/faqList`,
  viewFAQ: `${url}/faq/viewFAQ`,
  deleteFAQ: `${url}/faq/deleteFAQ`,
  addFAQ: `${url}/faq/addFAQ`,
  editFAQ: `${url}/faq/editFAQ`,

  // funded property
  getFundedProperty: `${url}/property/getFundedProperty`,
  viewFundedPropertyById: `${url}/property/viewFundedPropertyById`,

  // exited property
  getExitedProperties: `${url}/property/getExitedProperties`,
  viewExitedPropertyData: `${url}/property/viewExitedPropertyData`,

  // transaction
  fetchTransactions: `${url}/property/fetchTransactions`,
  getTransactionOfUserByProperty: `${url}/property/getTransactionOfUserByProperty`,
  fetchBankDetailsForDeposit: `${url}/property/fetchBankDetailsForDeposit`,
  fetchBankDetailsForWithdrawal: `${url}/property/fetchBankDetailsForWithdrawal`,
  approveWithdraw: `${url}/property/approveWithdraw`,
  rejectWithdraw: `${url}/property/rejectWithdraw`,
  // Reward
  getRewardTransactionList: `${url}/admin/getRewardTransactionList`,
  updateAdminRewardData: `${url}/admin/updateAdminRewardData`,

  // KYC
  kycList: `${url}/kyc/kycList`,
  viewKYC: `${url}/kyc/viewSingleUser`,
  approveUser: `${url}/kyc/approveUser`,
  rejectUser: `${url}/kyc/rejectUser`,

  // support management
  getSupportList: `${url}/support/getSupportList`,
  viewSupportContent: `${url}/support/viewSupportContent`,
  replySupportContent: `${url}/support/replySupportContent`,

  // Glossary Managment
  getGlossary: `${url}/glossary/glossaryContentList`,
  viewGlossary: `${url}/glossary/viewGlossary`,
  editGlossary: `${url}/glossary/editGlossary`,

  // How it works management
  getLinks: `${url}/welcome/getLinks`,
  viewLinkData: `${url}/welcome/viewLinkData`,
  editLink: `${url}/welcome/editLink`,
  deleteWelcomeDataContent: `${url}/welcome/deleteWelcomeDataContent`,
  addLink: `${url}/welcome/addLink`,
};

export default ApiConfig;
